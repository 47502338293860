import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeGrid } from "react-window";
import { useResizeDetector } from "react-resize-detector";
import { useDndScrolling } from "react-dnd-scrolling";
import ProductTileWrapper from "../ProductTileWrapper";
import {
  updateProductListInState,
  updateProductListSequenceMode,
} from "../../store/product-list/ProductListActions";
import { ProductSequenceData } from "../../store/product-list/ProductListTypes";
import {
  OVERLAY_TABS,
  QUICK_MOVE_DIRECTION,
  SEQUENCE_MODE_TYPE,
} from "../../utils/Constants";
import AppState from "../../store/AppState";
import {
  selectAreAllPagesFetched,
  selectIsFetchingProductIds,
  selectProductIdsCount,
  selectProductListSequenceMode,
  selectProgressLoadingPercentage,
} from "../../store/product-list/ProductListSelectors";
import { ViewContext } from "../../context/view-context";
import { DragItem } from "../ProductTile";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  resetAllProducAnalyticsView,
  updateProductListBySearch,
} from "../../store/product/ProductActions";
import { selectGetProductIds } from "../../store/product-list/ProductListSelectors";
import {
  selectIsFetchedBySearch,
  selectIsProductsLoading,
  selectRecentlyAddedNewProducts,
} from "../../store/product/ProductSelectors";
import { PRODUCT_TILE_HEIGHTS } from "../../utils/Constants";
import { useQuery } from "../../hooks/useQueryParams";
import { CircularProgress } from "@material-ui/core";
import useClipboard from "../../hooks/useClipBoard";
import { selectStoresLoadedState } from "../../store/store-list/StoreListSelectors";
import ProductListLoading from "./components/ProductListLoading";
import { StyledDiv, styleClasses } from "./ProductListStyles";
import { selectOverlay } from "store/overlay/OverlaySelectors";
import { selectIsCatalogsLoading } from "store/catalog/CatalogSelectors";
import { selectIsTopCategoriesLoading } from "store/category/CategorySelectors";

export interface SelectedSwatch {
  selectedSwatch: string;
  productImage: string;
}
export interface SelectedSwatchNode {
  [name: string]: SelectedSwatch;
}

interface Props {
  products: ProductSequenceData[];
}

const ProductList: React.FC<Props> = (props) => {
  const { products } = props;
  const query = useQuery();
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const [selectedItems, setSelectedItems] = useState<ProductSequenceData[]>([]);
  const [draggedItemsIds, setDraggedItemsIds] = useState<string[]>([]);
  const [activeItemId, setActiveItemId] = useState("");
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);
  const [insertLineIndex, setInsertLineIndex] = useState(-1);
  const [newSequence, setNewSequence] = useState<any[]>();
  const viewContext = useContext(ViewContext);
  const insertIndexRef = useRef({ insertIndex: -1 });
  const { width, height, ref } = useResizeDetector();
  const isFetchedBySearch = useSelector(selectIsFetchedBySearch);
  const productIdsCount = useSelector(selectProductIdsCount);
  const recentlyAddedNewProducts = useSelector(selectRecentlyAddedNewProducts);
  const isAllPagesFetched = useSelector(selectAreAllPagesFetched);
  const productIds = useSelector(selectGetProductIds);
  const isStoresLoaded = useSelector(selectStoresLoadedState);
  const isCatalogsLoading = useSelector(selectIsCatalogsLoading);
  const isTopCategoriesLoading = useSelector(selectIsTopCategoriesLoading);
  const isProductsLoading = useSelector(selectIsProductsLoading);
  const isFetchingProductIds = useSelector(selectIsFetchingProductIds);
  const progressValue = useSelector(selectProgressLoadingPercentage);

  const dispatch = useDispatch();
  const { addClipboardProductCodes } = useClipboard();
  const sequenceMode = useSelector((state: AppState) =>
    selectProductListSequenceMode(state),
  );
  const selectedOverlay = useSelector(selectOverlay);

  function findCommonObjectsAndMerge(array1, array2) {
    const commonObjects: any[] = [];
    const uniqueObjects: any[] = [];

    // Create a set of IDs from the second array for faster lookup
    const idSet = new Set(array2?.map((obj) => obj?.productId));
    // Loop through the first array
    for (let obj of array1) {
      // Check if the object's ID exists in the second array
      if (idSet.has(obj.product.productId) && obj.sequence === 0) {
        const updatedObj = { ...obj, isRecentlyAdded: true };
        commonObjects.push(updatedObj);
      } else {
        uniqueObjects.push(obj);
      }
    }
    // Merge commonObjects and uniqueObjects
    const mergedArray = [...commonObjects, ...uniqueObjects];

    // To make the list unique, you can use a Set and then convert it back to an array
    const uniqueMergedArray = Array.from(new Set(mergedArray));

    return uniqueMergedArray;
  }

  function doesIdExist(array, targetId) {
    for (const item of array) {
      const id = Object.keys(item)[0];
      if (id === targetId.toString()) {
        return true;
      }
    }
    return false;
  }

  React.useEffect(() => {
    if (recentlyAddedNewProducts.length) {
      const categoryTree = categoryPathQuery.split(";");
      const categoryId = categoryTree[categoryTree.length - 1];
      const exists = doesIdExist(recentlyAddedNewProducts, categoryId);
      if (exists) {
        recentlyAddedNewProducts.forEach((data) => {
          if (Object.keys(data)[0] === categoryId) {
            const uniqueList = findCommonObjectsAndMerge(
              products,
              data[categoryId],
            );
            setNewSequence(uniqueList);
          }
        });
      } else {
        setNewSequence(products);
      }
    } else {
      setNewSequence(products);
    }
  }, [products, categoryPathQuery, recentlyAddedNewProducts]);

  const resetSelectedItem = useCallback(() => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
    }
  }, [selectedItems.length]);

  useOnClickOutside(ref, resetSelectedItem);

  const itemMoveHandler = useCallback(
    (hoverIndex: number, insertLineIndex: number) => {
      setHoveredItemIndex(hoverIndex);
      setInsertLineIndex(insertLineIndex);
    },
    [],
  );

  const itemDragStartHandler = useCallback((dragItem: DragItem) => {
    setSelectedItems(dragItem.items);
    setDraggedItemsIds(dragItem.items.map((item) => item.productId));
    setActiveItemId(dragItem.draggedItem.productId);
  }, []);

  const dragCompleteHandler = useCallback(
    (dragItem: DragItem, index: number) => {
      if (dragItem) {
        let copiedItems = products.slice();
        let draggedItems = dragItem.items;
        let remainingItems = copiedItems.filter(
          (item) =>
            !draggedItems.find(
              (draggedItem) => draggedItem.productId === item.productId,
            ),
        );
        const insertIndex = insertIndexRef.current.insertIndex;
        let newInsertIndex = -1;
        if (insertIndex < copiedItems.length && insertIndex !== -1) {
          let index = insertIndex;
          do {
            const itemIdAtInsertIndex = copiedItems[index].productId;
            newInsertIndex = remainingItems.findIndex(
              (item) => item.productId === itemIdAtInsertIndex,
            );
            index += 1;
          } while (newInsertIndex < 0 && index < copiedItems.length);
        }
        if (newInsertIndex < 0) {
          newInsertIndex = remainingItems.length;
        }
        remainingItems.splice(newInsertIndex, 0, ...draggedItems);

        if (!isFetchedBySearch) {
          remainingItems.forEach((item) => {
            delete item.bounds;
          });
          for (const [i] of dragItem.items.entries()) {
            if (index < remainingItems.length - 1) {
              remainingItems[index + i].sequence =
                copiedItems[index].sequence + i;
              remainingItems[index + 1 + i].sequence =
                remainingItems[index + i].sequence + 1;
            }
          }
          const payload = {
            products: remainingItems,
            isSaveAction: true,
          };
          if (remainingItems.every((e, i) => e.productId === productIds[i])) {
            dispatch(updateProductListInState(payload));
            if (sequenceMode === SEQUENCE_MODE_TYPE.EDIT)
              dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.VIEW));
          } else {
            dispatch(updateProductListInState(payload));
            if (sequenceMode === SEQUENCE_MODE_TYPE.VIEW)
              dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
          }
        } else {
          dispatch(updateProductListBySearch(remainingItems));
        }
      }

      setDraggedItemsIds([]);
      setHoveredItemIndex(-1);
      setInsertLineIndex(-1);
    },
    [
      dispatch,
      insertIndexRef,
      products,
      isFetchedBySearch,
      sequenceMode,
      productIds,
    ],
  );

  const updateProductSequencesByQuickMove = useCallback(
    (productId: string, position: number, moveTo: number) => {
      let updatedProductList: ProductSequenceData[] = [];
      let selectedProductIdsSet = new Set();

      selectedItems.forEach(function (item) {
        selectedProductIdsSet.add(item.productId);
      });

      products.forEach(function (item) {
        if (!selectedProductIdsSet.has(item.productId)) {
          if (moveTo === QUICK_MOVE_DIRECTION.RIGHT)
            updatedProductList.push(item);
          if (item.productId === productId) {
            updatedProductList = updatedProductList.concat(selectedItems);
          }
          if (moveTo === QUICK_MOVE_DIRECTION.LEFT)
            updatedProductList.push(item);
        }
      });

      if (!isFetchedBySearch) {
        updatedProductList.forEach((item) => {
          delete item.bounds;
        });
        for (const [i] of selectedItems.entries()) {
          if (position < updatedProductList.length - 1) {
            if (moveTo === QUICK_MOVE_DIRECTION.RIGHT) {
              updatedProductList[position + 1 + i].sequence =
                products[position].sequence + 1 + i;
              if (i !== selectedItems.length - 1 && selectedItems.length > 1) {
                updatedProductList[position + 2 + i].sequence =
                  updatedProductList[position + 1 + i].sequence + 1;
              }
            }
            if (moveTo === QUICK_MOVE_DIRECTION.LEFT) {
              updatedProductList[position + i].sequence =
                products[position].sequence + i;
              updatedProductList[position + 1 + i].sequence =
                updatedProductList[position + i].sequence + 1;
            }
          }
        }

        const payload = {
          products: updatedProductList,
          isSaveAction: true,
        };

        dispatch(updateProductListInState(payload));

        if (sequenceMode === SEQUENCE_MODE_TYPE.VIEW) {
          dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
        }
      } else {
        dispatch(updateProductListBySearch(updatedProductList));
      }
    },
    [dispatch, isFetchedBySearch, selectedItems, sequenceMode, products],
  );

  const selectionChangeHandler = useCallback(
    (itemId: string, cmdKeyActive: boolean, shiftKeyActive: boolean) => {
      let newSelectedItemsIds: string[] = [];
      let newActiveItemId;

      const cards = products.slice();
      let previousSelectedItemsIds = selectedItems.map(
        (item) => item.productId,
      );
      let previousActiveItemId = activeItemId;

      if (cmdKeyActive) {
        if (
          previousSelectedItemsIds.indexOf(itemId) > -1 &&
          itemId !== previousActiveItemId
        ) {
          newSelectedItemsIds = previousSelectedItemsIds.filter(
            (id) => id !== itemId,
          );
        } else {
          newSelectedItemsIds = [...previousSelectedItemsIds, itemId];
        }
      } else if (shiftKeyActive && itemId !== previousActiveItemId) {
        const activeCardIndex = cards.findIndex(
          (c) => c.productId === previousActiveItemId,
        );
        const cardIndex = cards.findIndex((c) => c.productId === itemId);
        const lowerIndex = Math.min(activeCardIndex, cardIndex);
        const upperIndex = Math.max(activeCardIndex, cardIndex);
        newSelectedItemsIds = cards
          .slice(lowerIndex, upperIndex + 1)
          .map((c) => c.productId);
      } else {
        if (previousSelectedItemsIds.indexOf(itemId) > -1) {
          newSelectedItemsIds = [];
          setActiveItemId("");
        } else {
          newSelectedItemsIds = [itemId];
          newActiveItemId = itemId;
        }
      }

      const newSelectedCards = cards.filter((c) =>
        newSelectedItemsIds.includes(c.productId),
      );
      if (newActiveItemId) {
        setActiveItemId(newActiveItemId);
      }
      setSelectedItems(newSelectedCards);
    },
    [activeItemId, products, selectedItems],
  );

  const keyPressHandler = useCallback(
    (e: KeyboardEvent) => {
      const isCopyShortcutPressed = e.metaKey || e.ctrlKey;
      const isCopyKeyC = e.code === "KeyC";
      const isInputOrTextareaActive =
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement;
      const condition =
        selectedItems.length > 0 &&
        isCopyShortcutPressed &&
        !isInputOrTextareaActive &&
        isCopyKeyC;
      if (condition) {
        const payload = {
          productCodes: selectedItems.map((item) => item.productId),
        };
        addClipboardProductCodes(payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedItems],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [keyPressHandler]);

  useEffect(() => {
    if (selectedOverlay !== OVERLAY_TABS.ANALYTICS_OVERLAY)
      dispatch(resetAllProducAnalyticsView());
  }, [dispatch, selectedOverlay]);

  const containerWidth = width ?? 0;
  const containerHeight = height ?? 0;
  const rowCount = Math.ceil(products.length / viewContext.styles.column);
  const gridRef = useRef(null);
  useDndScrolling(gridRef, {});

  const renderProductListLoading = () => {
    if (!isAllPagesFetched) {
      const count =
        products.length === 0
          ? productIdsCount
          : productIdsCount - products.length;
      return <ProductListLoading count={count} />;
    }
    return null;
  };

  const isAllProductsLoading =
    isProductsLoading ||
    (progressValue !== 100 &&
      progressValue !== 0 &&
      !isFetchingProductIds &&
      !isAllPagesFetched);

  return (
    <>
      {!isStoresLoaded ||
      isCatalogsLoading ||
      isTopCategoriesLoading ||
      isFetchingProductIds ||
      isAllProductsLoading ? (
        <StyledDiv className={styleClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      ) : null}
      {renderProductListLoading()}
      <StyledDiv
        className={styleClasses.productList}
        ref={ref}
        fontSize={viewContext.styles.productListFZ}
      >
        {newSequence &&
          Array.isArray(newSequence) &&
          newSequence.length > 0 && (
            <FixedSizeGrid
              outerRef={gridRef}
              columnCount={viewContext.styles.column}
              columnWidth={containerWidth / viewContext.styles.column}
              rowCount={rowCount}
              rowHeight={
                PRODUCT_TILE_HEIGHTS.get(viewContext.styles.column) ?? 400
              }
              width={containerWidth}
              height={containerHeight}
              itemData={{
                draggedItemsIds,
                newSequence,
                selectedItems,
                onSelectionChange: selectionChangeHandler,
                onItemDragStart: itemDragStartHandler,
                onMove: itemMoveHandler,
                onDragComplete: dragCompleteHandler,
                OnQuickMoveCompleteAction: updateProductSequencesByQuickMove,
                hoveredItemIndex,
                insertLineIndex,
                insertIndexRef,
              }}
              itemKey={({ columnIndex, data, rowIndex }) => {
                const index =
                  rowIndex * viewContext.styles.column + columnIndex;
                const item = data.newSequence[index] ?? {};
                return `${item.productId}_${index}`;
              }}
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {ProductTileWrapper}
            </FixedSizeGrid>
          )}
      </StyledDiv>
    </>
  );
};

export default React.memo(ProductList);
