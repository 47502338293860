import { Theme, styled, Box, Card, Typography } from "@mui/material";

export const componentClasses = {
  root: "root",
  modal: "modal",
  resizable: "resizable",
  leftSection: "leftSection",
  draggable: "draggable",
  mainContent: "mainContent",
  mainSection: "mainSection",
  productTiles: "productTiles",
  outOfStock: "outOfStock",
  stock: "stock",
  belowInventoryThreshold: "belowInventoryThreshold",
  thresholdRange: "thresholdRange",
  emptyImageContainer: "emptyImageContainer",
  productCodes: "productCodes",
  productPrice: "productPrice",
  overlay: "overlay",
  showBorderOnHover: "showBorderOnHover",
  card: "card",
  baseProductCard: "baseProductCard",
  headLine: "headLine",
  paper: "paper",
  productCard: "productCard",
  baseproductDiv: "baseproductDiv",
  nonBaseproductDiv: "nonBaseproductDiv",
  leadingColorText: "leadingColorText",
  imageProductCodeDiv: "imageProductCodeDiv",
  nonPublishedText: "nonPublishedText",
  cardContentDiv: "cardContentDiv",
  stockBase: "stockBase",
  productTypeBase: "productTypeBase",
};
interface StyledCardProps {
  isPublished?: boolean; // Optional prop
  leadingColor?: boolean;
  showNotPublishedText?: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>(
  ({
    theme,
    isPublished = false,
    leadingColor = false,
    showNotPublishedText = false,
  }) => ({
    [`&.${componentClasses.card}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "142px",
      border: "none !important",
    },
    [`&.${componentClasses.baseProductCard}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "178px",
      height: "279px",
    },
    [`&.${componentClasses.productCard}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "142px",
      height: "255px",
      border: `${leadingColor && "2px solid" + theme.palette.primary.main}`, // Using theme color for border

      "&:hover": {
        cursor: isPublished ? "pointer" : "none",
        border:
          "2px solid " +
          (showNotPublishedText
            ? theme.palette.dangerColor.main
            : theme.palette.primary.main),
        [`& .${componentClasses.leadingColorText}`]: {
          color: theme.palette.primary.main,
          fontWeight: 400,
          Visibility: isPublished,
        },
      },
    },
  }),
);

interface StyledDivProps {
  isColumn?: boolean; // Optional prop
  leadingColor?: boolean;
  showNotPublishedText?: boolean;
  isPublished?: boolean;
  showLeadingText?: boolean;
}
export const StyledTypography = styled(Typography)<StyledDivProps>(
  ({
    theme,
    isColumn = false,
    showNotPublishedText = false,
    showLeadingText = false,
  }) => ({
    [`&.${componentClasses.headLine}`]: {
      padding: theme.spacing(4, 0, 2.5, 0),
      fontSize: "1rem",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      visibility: !showLeadingText && "hidden",
    },
    [`&.${componentClasses.stockBase}`]: {
      textAlign: "center",
      flex: 1,
      color: theme.palette.common.black,
      fontSize: "0.875rem",
      fontWeight: 700,
      height: "36px",
      alignContent: "center",
      paddingTop: "9px",
    },
    [`&.${componentClasses.nonPublishedText}`]: {
      fontSize: "0.875rem",
      fontWeight: "bold",
      textAlign: "left",
      padding: theme.spacing(2, 0, 2, 2.5),
      color: theme.palette.dangerColor.main,
      visibility: !showNotPublishedText ? "hidden" : "unset",
      [theme.breakpoints.down("md")]: {
        padding: "1.2% 0", // Using percentage padding here
      },
    },
    [`&.${componentClasses.productCodes}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: isColumn ? "0.75rem" : "1rem",
      fontWeight: "bold",
      overflow: "hidden",
      textOverflow: "ellipsis", // Enable ellipsis for overflowed content
      direction: "rtl", // Make the ellipsis appear at the start
      whiteSpace: "nowrap", // Restrict text to a single line
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(0.375),
      maxWidth: "100%", // Set a maximum width for the text container
    },
    [`&.${componentClasses.productPrice}`]: {
      color: theme.palette.common.black,
      fontSize: "0.75rem",
      fontWeight: isColumn ? "normal" : 400,
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis when text exceeds
      direction: "rtl", // Ellipsis at the start
      whiteSpace: "nowrap", // Restrict to one line only
      maxWidth: "100%",
      textAlign: "center",
    },
    [`&.${componentClasses.productTypeBase}`]: {
      color: theme.palette.common.black,
      fontSize: "0.75rem",
      fontWeight: "bold",
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis when text exceeds
      direction: "rtl", // Ellipsis at the start
      whiteSpace: "nowrap", // Restrict to one line only
      paddingBottom: theme.spacing(1),
      maxWidth: "100%",
      textAlign: "center",
    },
    [`&.${componentClasses.stock}`]: {
      textAlign: "center",
      color: "white",
      fontSize: "0.80rem",
      fontWeight: "600",
    },
  }),
);
export const StyledDiv = styled("div")<StyledDivProps>(
  ({ theme, isColumn = false, leadingColor = false, isPublished = false }) => ({
    [`&.${componentClasses.productTiles}`]: {
      display: "flex",
      height: "90%",
      marginBottom: "12px",
    },
    [`&.${componentClasses.leftSection}`]: {
      margin: theme.spacing(0, 3.75, 1.25, 0.625),
      height: "100%",
      position: "sticky",
      top: 55,
    },
    [`&.${componentClasses.draggable}`]: {
      width: "100%",
    },
    [`&.${componentClasses.imageProductCodeDiv}`]: {
      width: isColumn ? "89px" : "150px",
      margin: "auto",
    },
    [`&.${componentClasses.mainContent}`]: {
      overflow: "hidden",
      padding: theme.spacing(0, 3, 3, 4),
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: 1000,
      backgroundColor: "white",
      minWidth: "45vw",
      minHeight: "65vh",
      marginTop: theme.spacing(0.5),
      [theme.breakpoints.down("md")]: {
        minWidth: "45%", // Adjust minWidth when screen size reaches 'sm'
        padding: theme.spacing(0, 3),
      },
    },
    [`&.${componentClasses.cardContentDiv}`]: { width: "100%" },
    [`&.${componentClasses.outOfStock}`]: {
      background: theme.palette.dangerColor.main,
      height: "20px",
      width: "inherit",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [`&.${componentClasses.overlay}`]: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
    [`&.${componentClasses.showBorderOnHover}`]: {
      border: `1px solid ${!leadingColor ? theme.palette.common.black : theme.palette.primary.main}`, // Using theme color for border
      "&:hover": {
        border: "1px solid" + theme.palette.primary.main,
      },
    },
    [`&.${componentClasses.stockBase}`]: {
      textAlign: "center",
      flex: 1,
      color: theme.palette.common.black,
      fontSize: "0.875rem",
      fontWeight: 700,
      height: "36px",
      alignContent: "center",
      paddingTop: "9px",
    },

    [`&.${componentClasses.belowInventoryThreshold}`]: {
      background: theme.palette.neutral.yellow,
      height: "19px",
      width: "100%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [`&.${componentClasses.thresholdRange}`]: {
      background: theme.palette.grey[600],
      height: "19px",
      width: "100%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [`&.${componentClasses.emptyImageContainer}`]: {
      height: "142px",
      width: "142px",
      background: "lightgray",
      marginBottom: "5px",
      display: "flex",
    },

    [`&.${componentClasses.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      minWidth: "65%",
      minHeight: "80vh",
      height: "100%",
      position: "relative",
      display: "flex",
    },
    [`&.${componentClasses.baseproductDiv}`]: {
      height: "155px",
      width: "158px",
    },
    [`&.${componentClasses.nonBaseproductDiv}`]: {
      height: "89px",
      width: "91px",
    },
    [`&.${componentClasses.leadingColorText}`]: {
      fontSize: "0.75rem",
      width: "100%",
      fontWeight: `${leadingColor ? 700 : 400}`,
      textAlign: "center",
      marginBottom: "7px",
      color: `${leadingColor && theme.palette.primary.main}`,
      visibility: !isPublished ? "hidden" : "unset",
    },
  }),
);

export const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${componentClasses.mainSection}`]: {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "24px",
  },
}));

const useStyles = (theme: Theme) => {
  return {
    rightSection: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "200px",
    },
    editIcon: {
      cursor: "pointer",
      fontSize: "0.875rem",
    },
    publishedFlag: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    publishedContainer: {
      margin: "2px",
      padding: "1px",
    },
    checkBox: {
      margin: "5px",
      padding: "5px 8px",
    },
    editIconAlignment: {
      textAlign: "right",
    },
    closeButton: {
      display: "block",
      margin: "auto",
      width: "80px",
      position: "absolute",
      bottom: "10px",
      left: "0px",
      right: "0px",
    },
    idNotFoundText: {
      position: "absolute",
      right: "0%",
      left: "0%",
      top: "50%",
      bottom: "50%",
    },
    deleteButton: {
      padding: "5px",
      marginRight: "10px",
    },
    editForm: {
      display: "flex",
      justifyContent: "end",
    },
    updateButton: {
      marginRight: "5px",
    },
    formContainer: {
      padding: 0,
    },
    storeForm: {
      marginTop: "10px",
      marginLeft: "25px",
    },
    storeInput: {
      width: "150px",
      marginBottom: "25px",
    },
    storeInputLabel: {
      fontSize: "20px",
    },
    disableLink: {
      color: "grey",
    },
    cursor: {
      cursor: "not-allowed",
      textDecoration: "none",
    },
    formGroup: {
      margin: "10px",
      border: "1px solid #0000001f",
      width: "90%",
      position: "relative",
      height: "10vh",
    },
    formGroupLabel: {
      position: "absolute",
      top: "-9px",
      left: "20px",
      backgroundColor: "white",
      fontSize: "14px",
    },
    formGroupInput: {
      border: "none",
      marginTop: "12px",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    helpIcon: {
      top: "-12px",
      left: "50%",
      position: "absolute",
      width: "18px",
      color: "#505050",
    },
    noImage: {
      opacity: 0.5,
    },
    leadingColor: {
      backgroundColor: "transparent !important",
      color: theme.palette.primary.main,
      fontSize: "12px !important",
      marginBottom: "0px !important",
      maxWidth: "195px !important",
    },
  };
};
export default useStyles;
